.menu {
    width: 100%;
    max-width: 300px;
    max-height: 650px;
    /* min-height: 100%; */
    width: 300px;
    height: 100%;
    display: flex;
    border-radius: 20px;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;

    background: #fff;
    box-shadow: 0px 8px 54px 0px rgba(126, 130, 165, 0.16);

    position: absolute;
    z-index: 100000;
    left: 10%;
    /* bottom: 20px; */
}

.header__logo {
    margin-left: 20px;
}