.cash-system__slide-image {
  height: 32.5%;
  position: relative;
  top: 40px;
  right: 20px;
}

.cash-system__rhomb-wrapper {
  width: 572px;
  position: absolute;
  left: -286px;
}

.cash-system__ring-wrapper {
  width: 440px;
  position: absolute;
  top: -192px;
  right: 140px;
}

.cash-system__flicker-wrapper {
  width: 26px;
  position: absolute;
  top: 205px;
  left: 412px;
}

.cash-system__rhomb-rotate-wrapper {
  width: 112px;
  position: absolute;
  bottom: 89px;
  right: 162px;
}

.cash-system__half-circle-rotate-wrapper {
  width: 110.309px;
  position: absolute;
  bottom: 80.69px;
  left: 370.85px;
}

@media screen and (max-width: 1220px) {
  .cash-system__slide-image {
    height: 29%;
    top: 0;
    right: 0;
  }

  .cash-system__rhomb-wrapper {
    width: 515px;
    left: -257px;
  }

  .cash-system__ring-wrapper {
    width: 396px;
    top: -173px;
    right: 126px;
  }

  .cash-system__flicker-wrapper {
    width: 23px;
    top: 185px;
    left: 371px;
  }

  .cash-system__rhomb-rotate-wrapper {
    width: 101px;
    bottom: 80px;
    right: 146px;
  }

  .cash-system__half-circle-rotate-wrapper {
    width: 99px;
    bottom: 73px;
    left: 334px;
  }
}

@media screen and (max-width: 900px) {
  .cash-system__slide-image {
    height: 23%;
    top: 40px;
    right: 36px;
  }

  .cash-system__rhomb-wrapper {
    width: 61.28%;
    top: 165px;
    left: -33.59%;
  }

  .cash-system__ring-wrapper {
    width: 112.82%;
    top: -151px;
    right: -48.72%;
  }

  .cash-system__flicker-wrapper {
    width: 23px;
    top: 120px;
    left: 451px;
  }

  .cash-system__rhomb-rotate-wrapper {
    width: 15.38%;
    bottom: 80px;
    left: 20.74%;
  }

  .cash-system__half-circle-rotate-wrapper {
    width: 15.37%;
    bottom: 100px;
    left: unset;
    right: 19.86%;
  }
}

@media screen and (max-width: 700px) {
  .cash-system__slide-image {
    height: 37%;
    top: 14px;
    right: 0;
    align-self: center;
  }

  .cash-system__flicker-wrapper {
    display: none;
  }

  .cash-system__rhomb-rotate-wrapper {
    bottom: 308px;
    left: 29.74%;
  }

  .cash-system__half-circle-rotate-wrapper {
    top: 311.64px;
  }
}
