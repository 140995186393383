.delete-confirm-popup {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100vh;
    height: 100dvh; /* new browsers */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: 0;
}


.delete-confirm-popup__container {
    position: absolute;

    max-width: 300px;
    width: 80vw;
    box-sizing: border-box;

    border-radius: 20px;
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    border-radius: 16px;
    background: #E8EEFF;
}

.delete-confirm-popup__bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 1000vh;
    background-color: white;
    z-index: 99;
    opacity: 0.4;
}

.delete-confirm-popup__text {
    z-index: 101;

    text-align: center;
    color: #FFB31F;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 109.091% */

    color: #2864FF;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    /* 112.5% */
}

.delete-confirm-popup__button {
    z-index: 101;
    padding: 10px 20px;
    width: fit-content;
    text-transform: uppercase;

    border-radius: 12px;
    border: 2px solid #2864FF;


    /* color: #FFB31F; */
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 109.091% */

    border-radius: 16px;
    background: #E8EEFF;
    color: #2864FF;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    /* 112.5% */
}