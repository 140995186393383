.rhomb-with-hole {
  width: 100%;
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.rhomb-with-hole__rhomb {
  width: calc(100% / var(--size-divider));
  aspect-ratio: 1 / 1;
  background: var(--rhomb-color);
  transform: rotate(45deg);
  box-shadow: 0 64px 114px var(--shadow-color);
}

.rhomb-with-hole__rhomb-inner {
  width: calc((100% / var(--size-divider)) - (var(--hole-width) * 2));
  aspect-ratio: 1 / 1;
  background: var(--inner-color);
  transform: rotate(45deg);
  position: absolute;
}
