.ring {
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background: var(--ring-color);
}

.ring__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: var(--inner-size);
  height: var(--inner-size);
  border-radius: 50%;
  background: var(--inner-color);
}
