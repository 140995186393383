.demo-page {
    width: 100vw;
    height: 100vw;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 35px;
    position: relative;
    max-height: 800px;
    /* max-width: 1440px; */
    /* background-color: #fff; */
    padding-bottom: 97px;
}

.demo-page__connect-svg {
    margin-bottom: 25%;
    margin-right: 32%;
}

.demo-page .table-app {
    z-index: 10;
}

.demo-page .menu {
    z-index: 10;
}

.demo-page__rhomb {
    width: 477px;
    bottom: -208px;
    position: absolute;
    left: 161px;
}

.demo-page__ring {
    position: absolute;
    width: 301px;
    top: 0;
    left: 143px;
}

.demo-page__half-circle {
    position: absolute;
    right: 52px;
    top: 60px;
    width: 110px;
}

.demo-page__circle {
    position: absolute;
    width: 35px;
    bottom: -24px;
    right: 238px;
}

.demo__video {
    width: 100dvw;
    height: 100dvh;
    z-index: 1100;
    position: relative;
}

@media (max-width:1000px) {
    .demo-page {
        display: none;
    }

    .demo-page__ring {
        position: absolute;
        width: 301px;
        top: -74px;
        left: -143px;
    }

    .demo-page__circle {
        bottom: 224px;
        right: 328px;
    }
}