.popup {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  border-radius: 12px;
  background: var(--background-color-opacity);
  box-shadow: 0px 9px 54px 0px var(--shadow-color-secondary);
  backdrop-filter: blur(7px);
  box-sizing: border-box;
  padding: 40px;
}

.popup__close-btn {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 12px;
  right: 12px;
}

.popup__close-icon {
  width: 100%;
  height: 100%;
}

.popup__icon {
  width: 100px;
  height: 100px;
}

.popup__icon-fill {
  fill: var(--success-color);
}

.popup__icon-fill_type_error {
  fill: var(--error-color);
}

.popup__text {
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  padding-top: 40px;
}

.popup__subtext {
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  padding-top: 24px;
}
