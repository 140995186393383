.ring-with-shadow {
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background: var(--ring-color);
  box-shadow: 0 64px 114px var(--shadow-color);
}

.ring-with-shadow__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: var(--inner-size);
  height: var(--inner-size);
  border-radius: 50%;
  background: var(--inner-color);
  box-shadow: inset 0 114px 114px -37px var(--shadow-color);
}
