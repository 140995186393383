.heading {
  width: 100%;
  height: 100%;
  background: var(--color-block-active);
  display: flex;
  align-items: center;
  justify-content: center;
  /* scroll-snap-align: start; */
}

.heading__container {
  width: 100%;
  max-width: var(--max-width);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  padding: 32px 100px 24px;
}

.heading__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
}

.heading__text-logo-fill {
  fill: var(--neutral-secondary);
}

.heading__wrapper {
  width: 100%;
  max-width: 774px;
  height: 100%;
  max-height: 520px;
  flex: auto;
  display: flex;
  flex-direction: column;
}

.heading__main-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.heading__logo-wrapper {
  width: 264px;
  height: 88px;
}

.heading__logo {
  width: 100%;
  height: 100%;
}

.heading__logo-fill {
  fill: var(--neutral-secondary);
}

.heading__text-block {
  width: 100%;
  height: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.heading__title {
  color: var(--neutral-secondary);
  font-size: 64px;
  font-weight: 500;
  line-height: 1.125;
}

.heading__subtitle {
  color: var(--neutral-secondary);
  font-size: 30px;
  font-weight: 400;
  line-height: 1.27;
}

.heading__arrow {
  width: 31px;
  height: 31px;
  margin: 0 auto;
}

.heading__arrow-fill {
  fill: var(--semantic-quaternary);
}

.heading__half-circle-wrapper {
  width: 232px;
  position: absolute;
  top: -286.04px;
  right: 178.71px;
  z-index: 1;
}

.heading__ring-wrapper {
  width: 634px;
  position: absolute;
  right: -282px;
  z-index: 2;
}

.heading__rhomb-wrapper {
  width: 569.72px;
  position: absolute;
  bottom: -355.74px;
  right: 120.26px;
  transform: rotate(15deg);
  z-index: 0;
}

.heading__mobile-half-circle-wrapper {
  display: none;
}

.heading__flicker-wrapper {
  width: 44px;
  position: absolute;
  top: 312px;
  right: 418px;
  z-index: 1;
}

.heading__rotate-wrapper {
  width: 108.33px;
  position: absolute;
  top: 403.67px;
  right: 325.24px;
  z-index: 0;
}

@media screen and (max-width: 1220px) {
  .heading__container {
    padding: 32px 32px 24px;
  }

  .heading__content {
    gap: 16px;
  }

  .heading__wrapper {
    max-width: 650px;
    max-height: 426px;
  }

  .heading__logo-wrapper {
    width: 211px;
    height: 70px;
  }

  .heading__text-block {
    gap: 16px;
  }

  .heading__title {
    font-size: 52px;
  }

  .heading__subtitle {
    font-size: 24px;
  }

  .heading__half-circle-wrapper {
    width: 232px;
    top: -229px;
    right: 143px;
  }

  .heading__ring-wrapper {
    width: 507px;
    right: -226px;
  }

  .heading__rhomb-wrapper {
    width: 456px;
    bottom: -285px;
    right: 96px;
  }

  .heading__flicker-wrapper {
    width: 35px;
    top: 250px;
    right: 334px;
  }

  .heading__rotate-wrapper {
    width: 86px;
    top: 323px;
    right: 260px;
  }
}

@media screen and (max-width: 900px) {
  .heading__container {
    max-width: var(--max-width-mobile);
    padding: 40px 25px 30px;
  }

  .heading__content {
    align-items: center;
  }

  .heading__wrapper {
    max-width: 340px;
    max-height: 368px;
    align-items: center;
  }

  .heading__main-content {
    max-height: 328px;
    align-items: center;
  }

  .heading__logo-wrapper {
    width: 180px;
    height: 60px;
  }

  .heading__text-block {
    align-items: center;
    text-align: center;
    gap: 12px;
  }

  .heading__title {
    font-size: 28px;
    line-height: 1.121;
  }

  .heading__subtitle {
    font-size: 16px;
    line-height: 1.25;
  }

  .heading__link-button-wrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 0 9px;
  }

  .heading__half-circle-wrapper {
    width: 37.03%;
    max-width: 160px;
    top: unset;
    bottom: 41.15px;
    right: -19.41%;
  }

  .heading__ring-wrapper {
    width: 81.28%;
    max-width: 380px;
    right: unset;
    bottom: -112px;
  }

  .heading__rhomb-wrapper {
    width: 73.04%;
    max-width: 330px;
    bottom: 84.13px;
    left: -46.92%;
    transform: rotate(105deg);
  }

  .heading__mobile-half-circle-wrapper {
    display: block;
    width: 37.03%;
    max-width: 144px;
    position: absolute;
    top: -91.24px;
    left: -15.91%;
  }

  .heading__flicker-wrapper {
    width: 4.62%;
    max-width: 44px;
    top: unset;
    bottom: 205px;
    right: 28.46%;
  }

  .heading__rotate-wrapper {
    width: 13.89%;
    max-width: 70px;
    top: unset;
    bottom: 159.54px;
    left: 28.67%;
  }
}
