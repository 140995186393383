.tariff-card-form {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
}

.tariff-card-form__title-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tariff-card-form__title {
  font-size: 26px;
  font-weight: 500;
  line-height: 1.15;
}

.tariff-card-form__back-button {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tariff-card-form__back-icon {
  width: 100%;
  height: 100%;
}

.tariff-card-form__back-icon-fill {
  fill: var(--semantic-active);
}

.tariff-card-form__type-box {
  width: 100%;
  margin-top: 50px;
}

.tariff-card-form__form {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  margin-top: 40px;
}

.tariff-card-form__input-box_type_wide {
  grid-column: span 2;
}

.tariff-card-form__input-box_type_checkbox {
  margin-top: 4px;
}

@media screen and (max-width: 1220px) {
  .tariff-card-form__title {
    font-size: 22px;
  }

  .tariff-card-form__type-box {
    margin-top: 32px;
  }

  .tariff-card-form__form {
    margin-top: 32px;
  }
}

@media screen and (max-width: 1100px) {
  .tariff-card-form__form {
    grid-template-columns: 1fr;
  }

  .tariff-card-form__input-box_type_wide {
    grid-column: span 1;
  }
}

@media screen and (max-width: 900px) {
  .tariff-card-form__title {
    font-size: 18px;
    line-height: 1.11;
  }

  .tariff-card-form__back-button {
    width: 20px;
    height: 20px;
  }

  .tariff-card-form__type-box {
    margin-top: 26px;
  }

  .tariff-card-form__form {
    margin-top: 20px;
  }

  .tariff-card-form__input-box_type_checkbox {
    margin-top: 8px;
  }
}
