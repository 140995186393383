.image-element {
  width: 100%;
  max-width: 626px;
  height: 100%;
  max-height: var(--max-height);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 700px) {
  .image-element {
    max-width: 100vw;
    max-height: 488px;
    justify-content: center;
    align-items: flex-end;
  }
}
