.tariff-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 30px;
  background: var(--background-color-opacity);
  box-shadow: 0px 9px 54px 0px var(--shadow-color-secondary);
  backdrop-filter: blur(7px);
  box-sizing: border-box;
  padding: 50px 40px 40px;
}

.tariff-card__content {
  width: 100%;
  height: 100%;
}

.tariff-card__button {
  width: 100%;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 27px 0px;
}

.tariff-card__button_disabled {
  pointer-events: none;
  user-select: none;
}

.tariff-card__button-text {
  color: var(--neutral-secondary);
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.21;
}

@media screen and (max-width: 1220px) {
  .tariff-card {
    gap: 12px;
    border-radius: 24px;
    padding: 40px 32px 32px;
  }

  .tariff-card__button {
    height: 72px;
    border-radius: 16px;
    padding: 22px 0px;
  }

  .tariff-card__button-text {
    font-size: 22px;
  }
}

@media screen and (max-width: 900px) {
  .tariff-card {
    max-height: 464px;
    border-radius: 20px;
    padding: 26px 16px 16px;
  }

  .tariff-card__button {
    height: 48px;
    border-radius: 10px;
    padding: 16px 0px;
  }

  .tariff-card__button-text {
    font-size: 14px;
    line-height: 1.14;
  }
}
