.checkbox {
  width: 100%;
}

.checkbox__container {
  width: 100%;
  display: grid;
  grid-template-columns: 22px 1fr;
  align-items: center;
  gap: 14px;
}

.checkbox__invisible-input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.checkbox__pseudo-item {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid var(--form-active);
  box-sizing: border-box;
  cursor: pointer;
}

.checkbox__check-icon {
  width: 22px;
  height: 22px;
  position: absolute;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.checkbox__pseudo-item_checked .checkbox__check-icon {
  opacity: 1;
}

.checkbox__check-icon-fill {
  fill: var(--form-active);
}

.checkbox__check-icon-stroke {
  stroke: var(--neutral-secondary);
}

.checkbox__label {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.14;
}

.checkbox__label a {
  color: var(--form-active);
}

@media screen and (max-width: 900px) {
  .checkbox__container {
    grid-template-columns: 28px 1fr;
    gap: 12px;
  }

  .checkbox__pseudo-item {
    width: 28px;
    height: 28px;
  }

  .checkbox__check-icon {
    width: 28px;
    height: 28px;
  }

  .checkbox__label {
    font-size: 12px;
    line-height: 1.16;
  }
}
