.telegram__slide-image {
  height: 70%;
  position: relative;
  bottom: 25px;
  left: 44px;
}

.telegram__circle-wrapper {
  width: 280px;
  position: absolute;
  top: -63px;
  right: 234px;
  opacity: 0.4;
}

.telegram__half-circle-wrapper {
  width: 244px;
  position: absolute;
  bottom: 90px;
  right: -21.8px;
}

.telegram__flicker-wrapper {
  width: 35px;
  position: absolute;
  bottom: 68px;
  left: 312px;
}

.telegram__rotate-wrapper {
  width: 154px;
  position: absolute;
  top: 264px;
  right: 64px;
}

@media screen and (max-width: 1220px) {
  .telegram__slide-image {
    height: 62%;
    bottom: 0;
    left: 40px;
  }
  
  .telegram__circle-wrapper {
    width: 252px;
    top: -144px;
    right: 358px;
  }
  
  .telegram__half-circle-wrapper {
    width: 220px;
    bottom: -158px;
    right: -20px;
  }
  
  .telegram__flicker-wrapper {
    width: 32px;
    bottom: 61px;
    left: 281px;
  }
  
  .telegram__rotate-wrapper {
    width: 139px;
    top: 238px;
    right: 58px;
  }
}

@media screen and (max-width: 900px) {
  .telegram__slide-image {
    height: 46%;
    left: 0;
  }
  
  .telegram__circle-wrapper {
    width: 34.87%;
    top: -68px;
    left: 22.31%;
  }
  
  .telegram__half-circle-wrapper {
    width: 9.78%;
    bottom: 199.37px;
    right: -10.63%;
  }
  
  .telegram__flicker-wrapper {
    width: 3.33%;
    top: 97px;
    left: unset;
    right: 17.95%;
  }
  
  .telegram__rotate-wrapper {
    width: 32.31%;
    top: 68px;
    left: -25.15%;
  }
}

@media screen and (max-width: 700px) {
  .telegram__slide-image {
    height: 64%;
    top: 20px;
    left: 20px;
    align-self: center;
  }
  
  .telegram__half-circle-wrapper {
    bottom: 299.37px;
    right: 10.63%;
  }
  
  .telegram__rotate-wrapper {
    top: 308px;
    left: -16.15%;
  }
}