.product-card {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 28px;
  align-items: start;
  gap: 46px;
}

.product-card__title {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.72px;
  text-transform: uppercase;
}

.product-card__title__size {
  text-transform: lowercase;
  font-size: 14px;
}

.product-card__text {
  color: blue;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  margin-top: 6px;
}

.product-card__price {
  color: blue;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  margin-top: 12px;
}

.product-card__price_size_small {
  font-size: 14px;
  letter-spacing: 0.56px;
}

.product-card__add-btn {
  width: 28px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.product-card__add-btn_add:active {
  opacity: 0;
}

.product-card__add-btn_checked:active {
  opacity: 0;
}

.product-card__btn-icon {
  width: 100%;
  height: 100%;
}