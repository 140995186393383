.screen-wrapper {
  width: 100%;
  max-width: var(--max-width);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media screen and (max-width: 900px) {
  .screen-wrapper {
    /* max-width: var(--max-width-mobile); */
  }
}