.updates__slide-image {
  height: 75%;
  position: relative;
  left: 12px;
}

.updates__rhomb-wrapper {
  width: 477px;
  position: absolute;
  bottom: 105px;
  right: -238px;
}

.updates__ring-wrapper {
  width: 286px;
  position: absolute;
  top: -33px;
  left: 287px;
}

.updates__rotate-wrapper {
  width: 36px;
  position: absolute;
  top: 49.18px;
  right: 326.82px;
}

@media screen and (max-width: 1220px) {
  .updates__slide-image {
    height: 68%;
    left: 11px;
  }
  
  .updates__rhomb-wrapper {
    width: 429px;
    bottom: 61px;
    right: -214px;
  }
  
  .updates__ring-wrapper {
    width: 257px;
    top: -30px;
    left: 258px;
  }
  
  .updates__rotate-wrapper {
    width: 32px;
    top: 44px;
    right: 294px;
  }
}

@media screen and (max-width: 900px) {
  .updates__slide-image {
    height: 50%;
    left: -10px;
  }
  
  .updates__rhomb-wrapper {
    width: 76.92%;
    top: 52px;
    right: -38.46%;
  }
  
  .updates__ring-wrapper {
    width: 60.51%;
    top: 202px;
    left: -20%;
  }
  
  .updates__rotate-wrapper {
    width: 4.69%;
    top: 8px;
    right: 35.33%;
  }
}

@media screen and (max-width: 700px) {
  .updates__slide-image {
    height: 78%;
    bottom: 25px;
    left: 0;
  }
}