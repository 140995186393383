.loading {
    width: 100%;
    height: 100vh;
    height: 100dvh; /* new browsers */
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--bg-color);
    /* justify-content: flex-end; */

    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    padding: 30px 0 0 0;
    justify-content: center;
}

.loading__title {
    color: #1F1F2D;
    -webkit-text-stroke: 1px #555B7D;
    font-family: Mayak;
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: 84px;
    letter-spacing: 3.2px;
    text-transform: uppercase;
    margin: 40px 0 0 0;
    z-index: 5;
}

.loading__logo {
    max-width: 200px;
    width: 100%;
    max-height: 288px;
    height: 100%;
    margin-left: -30px;
    z-index: 1;
    position: relative;
}

.loading__star-bg {
    position: absolute;
    top: 0;
    /* left: -60px; */
    z-index: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}

.loading__line {
    margin-top: 30px;
    overflow: hidden;
    width: 100%;
    max-width: 180px;
    margin-bottom: -50px;
    z-index: 0;
}

.loading__line__box {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* margin: 0 0 -200px 0; */
    overflow: hidden;
}

.loading__terms {
    color: #FFF;
    text-align: center;
    /*  */
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 133.333%;
    opacity: 0.54;
    margin: 0;
    position: relative;
    z-index: 2;
    padding: 50px 0 30px 0;
}

.loading__terms-promo-link{
    /* color: #555B7D; */
    opacity: 1;
}

.loading__progress-box {
    width: 217px;
    height: 2px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    border-radius: 16px;
    background-color: #2A2C3C;
    margin: 16px 0 0;
}

.loading__progress {
    transition: all 0.1s ease-in-out;
    height: 100%;
    border-radius: 16px;
    background-color: #555B7D;
}

.loading__perent {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 133.333%;
    letter-spacing: 0.36px;
    text-transform: uppercase;
    margin: 12px 0 0;
    color: #FFF;
}

.loading__blue {
    left: 0;
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 214px;
    /* background: linear-gradient(180deg, rgba(30, 30, 45, 0.00) 2.68%, #fff 57.3%); */

    display: flex;
    justify-content: center;
    align-items: center;
}