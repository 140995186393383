.icons__slide-image {
  height: 54.5%;
  position: relative;
  right: 60px;
}

.icons__half-circle-wrapper {
  width: 381.588px;
  position: absolute;
  bottom: 0;
  right: -225.53px;
}

.icons__rhomb-wrapper {
  width: 178px;
  position: absolute;
  top: 0px;
  left: 42px;
}

.icons__big-flicker-wrapper {
  width: 128px;
  position: absolute;
  bottom: 75px;
  right: 89px;
}

.icons__small-flicker-wrapper {
  width: 46px;
  position: absolute;
  bottom: 96px;
  left: 310px;
}

@media screen and (max-width: 1220px) {
  .icons__slide-image {
    height: 49%;
    right: 54px;
  }

  .icons__half-circle-wrapper {
    width: 343px;
    bottom: -157px;
    right: -203px;
  }

  .icons__rhomb-wrapper {
    width: 160px;
    top: -80px;
    left: 218px;
  }

  .icons__big-flicker-wrapper {
    width: 115px;
    bottom: 68px;
    right: 80px;
  }

  .icons__small-flicker-wrapper {
    width: 41px;
    bottom: 86px;
    left: 279px;
  }
}

@media screen and (max-width: 900px) {
  .icons__slide-image {
    height: 35%;
  }

  .icons__rhomb-wrapper {
    width: 160px;
    top: 20px;
    left: -50px;
  }
}

@media screen and (max-width: 700px) {
  .icons__slide-image {
    height: 63%;
    top: 20px;
    right: 0;
    align-self: center;
  }

  .icons__half-circle-wrapper {
    display: none;
  }

  .icons__rhomb-wrapper {
    display: none;
  }

  .icons__big-flicker-wrapper {
    display: none;
  }

  .icons__small-flicker-wrapper {
    display: none;
  }
}
