.square {
  width: 100%;
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.square__rhomb {
  width: calc(100% / var(--size-divider));
  aspect-ratio: 1 / 1;
  background: var(--rhomb-color);
  transform: rotate(45deg);
  box-shadow: 0 64px 114px var(--shadow-color);
}
