@import url(./assets/fonts/Inter/stylesheet.css);
@import url(./assets/fonts/SF/stylesheet.css);
body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--website-background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, h1, h2, h3, h4, h5, h6, blockquote, span {
  padding: 0;
  margin: 0;
  color: var(--neutral-active);
  font-style: normal;
  white-space: pre-line;
}

span {
  color: inherit;
}

a {
  font-style: normal;
  text-decoration: none;
  color: var(--neutral-active);
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  padding: 0;
  margin: 0;
  color: var(--neutral-active);
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img {
  object-fit: contain;
  pointer-events: none;
  user-select: none;
}

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

input, textarea, label {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  color: var(--neutral-active);
  background-color: transparent;
  border: none;
  outline: transparent;
  padding: 0;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  -webkit-box-shadow: 0 0 0 500px var(--website-background) inset !important;
  /* можно изменить на любой вариант цвета */
  -webkit-text-fill-color: var(--neutral-active) !important;
  background-color: var(--form-secondary) !important;
  background-clip: content-box !important;
  position: relative;
  z-index: 0;
}

[popover] {
  background-color: transparent;
  border: none;
  padding: 0;
}

[popover]::backdrop {
  background-color: var(--popup-background);
}

:root {
  --max-width: 1440px;
  --max-height: 800px;

  --max-width-mobile: 500px;
  --max-height-mobile: 760px;

  --website-background: #FFFFFF;
  --background-color-opacity: rgba(255, 255, 255, 0.90);
  --popup-background: rgba(0, 0, 0, 0.5);

  --shadow-color: rgba(120, 127, 153, 0.14);
  --shadow-color-secondary: rgba(182, 189, 214, 0.25);

  --neutral-active: #434A70;
  --neutral-secondary: #FFFFFF;
  --neutral-tertiary: #D3D3D3;

  --color-block-active: #70397E;
  --color-block-primary: #7E438D;
  --color-block-secondary: #964FA8;
  --color-block-tertiary: #B387BE;
  --color-block-shadow: #602D6D;

  --footer-active: #592666;
  --footer-primary: #5C2869;
  --footer-secondary: #612A6E;
  --footer-tertiary: #BF92CB;
  --footer-quaternary: #B97AC9;
  --footer-shadow: #50205D;

  --semantic-active: #B6BBD6;
  --semantic-secondary: #E4E7F4;
  --semantic-tertiary: #7499FA;
  --semantic-quaternary: #FFC7C7;

  --swiper-main-color: #2864FF;
  --swiper-secondary-color: #555B7D;

  --tariff-button-color: #52BCBC;
  --tariff-button-hover: #48a7a7;
  --tariff-button-active: #62dada;

  --form-active: #1053FF;
  --form-secondary: #F5FAFF;
  --form-button-color: #5791C7;
  --form-button-hover: #4882b7;
  --form-button-active: #62b2e2;
  --form-button-disabled: #a5bccf;

  --scroll-button-color: #43D0A6;
  --scroll-button-hover: #2AE189;
  --scroll-button-active: #A7E2A2;

  --link-button-color: #2864FF;
  --link-button-hover: #1740a8;
  --link-button-active: #5184f3;

  --success-color: #7ed476;
  --error-color: #9b0202;

  --color-accent-primary: #FFC582;
  --color-accent-secondary: #9FCBFF;
  --color-accent-tertiary: #FF63E6;
  --color-accent-quaternary: #FFAF8D;
  --color-accent-quinary: #DCCCFF;
  --color-accent-senary: #A9FFA2;
  --color-accent-septenary: #FFB763;
  --color-accent-octonary: #82D9FF;
  --color-accent-nonary: #AEB6FF;
  --color-accent-denary: #FFB9F0;
  --color-accent-duodenary: #FFEC8B;
  --color-accent-tredenary: #8DEAFF;
  --color-accent-quattuordenary: #BADEFF;
  --color-accent-quindenary: #A7E2A2;
  --color-accent-sexdenary: #CFFFE8;
  --color-accent-septendenary: #B9FFAE;
  --color-accent-octodenary: #FFCB67;
  --color-accent-novemdenary: #63DAFF;
  --color-accent-vigintenary: #D9CCFF;
  --color-accent-undecenary: #FFB99C;
  --color-accent-duodecenary: #8AF8FF;
  --color-accent-tredecenary: #638FFF;
  --color-accent-quattuordecenary: #CFE2FF;
  --color-accent-quindecenary: #DBC2FC;
  --color-accent-sexdecenary: #CEDFFF;
  --color-accent-septendecenary: #FFB299;
  --color-accent-octodecenary: rgba(108, 112, 212, 0.54);
  --color-accent-novemdecenary: #63E3FF;
  --color-accent-decenary: #CCFFAC;

  --accent-color-primary: #E6DDFF;
  --accent-color-secondary: #FF7272;
  --accent-color-tertiary: #FFCB9C;
  --accent-color-quaternary: #FFDFA2;
  --accent-color-quinary: #FFCCCC;
  --accent-color-senary: #C4F07B;
  --accent-color-septenary: #FFE1BA;
  --accent-color-octonary: #A18BFA;
  --accent-color-nonary: #FF9567;
  --accent-color-denary: #FFEBC4;
  --accent-color-quindenary: #FFD8CF;
  --accent-color-sexdenary: #A863FF;
  --accent-color-septendecenary: #D0BAFF;
}

