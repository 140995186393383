.history {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 6px 52px;
  overflow: scroll;
}

.history__title-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 12px;
}

.history__title {
  /* color: #555B7D;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 1.12px;
  text-transform: uppercase; */

  color: #8A487C;
  font-family: Oswald;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
  /* 114.286% */
  letter-spacing: 0.56px;
}

.history__close-btn {
  width: 32px;
  height: 32px;
  display: flex;
}

.history__close-icon {
  width: 100%;
  height: 100%;
}

.history__close-icon-stroke {
  stroke: blue;
}

.history__orders-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 26px;
}

.history__nothing-text {
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.72px;
  padding: 50px 12px 0;
}