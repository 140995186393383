.link-button {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: var(--scroll-button-color);
  box-sizing: border-box;
  padding: 27px 96px;

  color: var(--neutral-secondary);
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.21;

  position: relative;
  z-index: 11;
}

.link-button_buy-screen {
  padding: 27px 108px;
}

@media screen and (max-width: 1220px) {
  .link-button {
    font-size: 22px;
    border-radius: 16px;
    padding: 22px 80px;
  }

  .link-button_buy-screen {
    padding: 22px 86px;
  }
}

@media screen and (max-width: 900px) {
  .link-button {
    width: 100%;
    font-size: 14px;
    line-height: 1.14;
    border-radius: 10px;
    padding: 16px 0;
  }

  .link-button_buy-screen {
    width: 220px;
    padding: 16px 0;
  }
}
