.partners {
  width: 100vw;
  height: 100vw;
  max-height: var(--max-height);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  box-sizing: border-box;
  padding: 60px 100px;
  background-color: #fff;
}

.partners__rhomb-wrapper {
  width: 592px;
  position: absolute;
  top: -305px;
  right: 366px;
}

.partners__ring-wrapper {
  width: 440px;
  position: absolute;
  bottom: 51px;
  left: -104px;
}

.partners__half-circle-wrapper {
  width: 158.355px;
  position: absolute;
  bottom: -107.1px;
  right: 562.09px;
  /* opacity: 0; */
}

.partners__big-flicker-wrapper {
  width: 46px;
  position: absolute;
  top: 126px;
  left: 274px;
}

.partners__small-flicker-wrapper {
  width: 35px;
  position: absolute;
  bottom: 55px;
  right: 203px;
}

.partners__rotate-wrapper {
  width: 56.891px;
  position: absolute;
  bottom: 58.77px;
  right: 596.21px;
}

@media screen and (max-width: 1350px) {
  .partners {
    padding: 60px 32px;
  }
}

@media screen and (max-width: 1220px) {
  .partners__rhomb-wrapper {
    width: 474px;
    top: -118px;
    right: 293px;
  }

  .partners__ring-wrapper {
    width: 352px;
    bottom: 41px;
    left: -83px;
  }

  .partners__half-circle-wrapper {
    width: 127px;
    bottom: -86px;
    right: 450px;
  }

  .partners__big-flicker-wrapper {
    width: 37px;
    top: 101px;
    left: 219px;
  }

  .partners__small-flicker-wrapper {
    width: 28px;
    bottom: 44px;
    right: 162px;
  }

  .partners__rotate-wrapper {
    width: 46px;
    bottom: 47px;
    right: 477px;
  }
}

@media screen and (max-width: 1024px) {
  .partners {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 900px) {
  .partners {
    max-height: var(--max-height-mobile);
    gap: 0px;
    padding: 0 12px 94px 12px;
    height: 100dvh;
  }

  .partners__rhomb-wrapper {
    width: 100%;
    top: -150px;
    right: 0;
  }

  .partners__ring-wrapper {
    width: 76.92%;
    bottom: 144px;
    left: -38.46%;
  }

  .partners__half-circle-wrapper {
    width: 90px;
    bottom: -46px;
    right: 10px;
  }

  .partners__big-flicker-wrapper {
    width: 6.41%;
    top: 208px;
    left: 11.03%;
  }

  .partners__small-flicker-wrapper {
    width: 8.97%;
    bottom: 267px;
    right: 10.26%;
  }

  .partners__rotate-wrapper {
    width: 24.8px;
    bottom: 60px;
    right: 40px;
  }
}