.tablet__slide-image {
  height: 100%;
  position: relative;
  left: 276px;
}

.tablet__half-circle-wrapper {
  width: 288.806px;
  position: absolute;
  top: -286.04px;
  left: 227.71px;
  z-index: 1;
}

.tablet__ring-wrapper {
  width: 634px;
  position: absolute;
  bottom: 79px;
  left: -254px;
  z-index: 2;
}

.tablet__rhomb-wrapper {
  width: 585.587px;
  position: absolute;
  bottom: -371.82px;
  left: 134.11px;
  transform: rotate(165deg);
  z-index: 0;
}

.tablet__hole-rhomb-wrapper {
  width: 557.2px;
  position: absolute;
  right: -278.2px;
}

.tablet__flicker-wrapper {
  width: 44px;
  position: absolute;
  top: 186px;
  right: 712px;
}

.tablet__rotate-wrapper {
  width: 108.33px;
  position: absolute;
  bottom: 271.62px;
  left: 658.15px;
}

@media screen and (max-width: 1260px) {
  .tablet__slide-image {
    left: 160px;
  }
}

@media screen and (max-width: 1220px) {
  .tablet__slide-image {
    height: 90%;
    left: 144px;
  }

  .tablet__half-circle-wrapper {
    width: 231px;
    top: -229px;
    left: 182px;
  }

  .tablet__ring-wrapper {
    width: 507px;
    bottom: 63px;
    left: -203px;
  }

  .tablet__rhomb-wrapper {
    width: 468px;
    bottom: -297px;
    left: 107px;
  }

  .tablet__hole-rhomb-wrapper {
    width: 446px;
    right: -223px;
  }

  .tablet__flicker-wrapper {
    width: 35px;
    top: 149px;
    right: 570px;
  }

  .tablet__rotate-wrapper {
    width: 87px;
    bottom: 217px;
    left: 527px;
  }
}

@media screen and (max-width: 900px) {
  .tablet__slide-image {
    height: 60%;
    left: 64px;
  }

  .tablet__half-circle-wrapper {
    width: 40.86%;
    top: -158.02px;
    left: 10.71%;
  }

  .tablet__ring-wrapper {
    width: 89.69%;
    max-width: 435px;
    bottom: unset;
    left: -57.44%;
  }

  .tablet__rhomb-wrapper {
    width: 82.84%;
    max-width: 340px;
    bottom: -120.32px;
    left: -8.38%;
    transform: rotate(165deg);
  }

  .tablet__hole-rhomb-wrapper {
    width: 85.03%;
    max-width: 360px;
    top: 109px;
    right: -47.33%;
  }

  .tablet__flicker-wrapper {
    width: 6.22%;
    top: 145px;
    left: 18.94%;
  }

  .tablet__rotate-wrapper {
    width: 15.33%;
    max-width: 65px;
    bottom: 218.69px;
    left: unset;
    right: 4.91%;
  }
}

@media screen and (max-width: 700px) {
  .tablet__slide-image {
    height: 86%;
    top: -10px;
    left: 6px;
  }
}


.bg {
  background: #70397E;
}