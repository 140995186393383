.table-schema {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-template-rows: repeat(5, minmax(0, 1fr));
    grid-template-areas: 'table6 table5 table4 table3 table2'
        'table7 . . . table2'
        'table8 . table12 .  table1'
        '. . . .  table1'
        'table9 table10 table11 table11 .';
    column-gap: 8px;
    row-gap: 8px;
    padding: 20px 20px 19.811px 20px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    border-radius: 20px;
    background: rgba(255, 255, 255, 0.60);
}

.table__box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    border-radius: 18px;
    padding: 24px 12px;
    box-sizing: border-box;
    position: relative;
    pointer-events: none;

    transition: background-color 1.5s ease-in-out, color 1.5s ease-in-out;
    background-color: #E9E9ED;
    /* Default background */
    color: #8A8AA3;
    /* Default text color */
    font-family: "Nunito Sans";
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
}

.table__box__numbers {
    transition: color 1.5s ease-in-out;
    color: #8A8AA3;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
}

.table__box__icons {
    display: flex;
    justify-content: start;
    gap: 4px;
    position: absolute;
    top: 8px;
    right: 8px;
}

.table__box__icons__img {
    width: 20px;
    height: 20px;
}

.table__box.active {
    color: #FFB31F;
    /* Text color for active tables */
}

@media (max-width: 850px) {
    .table-schema {
        display: none;
    }
}

/* Optional: Keyframe background animation for non-active tables */
@keyframes backgroundAnimation {
    0% {
        background-color: #8B8B9E;
    }
    50% {
        background-color: #E9E9ED;
    }
    100% {
        background-color: #8B8B9E;
    }
}

/* Apply animation only to table boxes that aren't active */
.table__box.active {
    animation: backgroundAnimation 2s ease-in-out;
    /* background-color: #8A8AA3; */
    /* color: #E9E9ED; */
}