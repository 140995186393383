.snap-section {
  width: 100vw;
  /* scroll-snap-align: start; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  /* overflow-y: visible; */
  background-color: #fff;
  height: 100dvh;
}

/* .snap-section:first-of-type {
  overflow: hidden;
}

.snap-section:nth-child(6) {
  overflow-x: hidden;
}

.snap-section:nth-child(9) {
  overflow: hidden;
}

.snap-section:last-of-type {
  overflow: hidden;
} */