.swiper-element {
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.swiper-element__swiper {
  width: 100vw;
  height: 100vh;
  height: 100dvh; /* new browsers */
}

.swiper-element__nav-button {
  width: 56px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--website-background);
  box-shadow: 0px 9px 14px 0px var(--shadow-color);
  transition: opacity 0.2s ease-in-out;

  position: absolute;
  left: 30px;
  z-index: 10;
}

.swiper-element__button-next {
  transform: scaleX(-1);
  right: 30px;
  left: unset;
}

.swiper-element__nav-button_type_color {
  background: var(--footer-active);
}

.swiper-button-disabled {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.swiper-element__nav-icon-fill {
  fill: var(--color-block-secondary);
}

.swiper-element__nav-button_type_color .swiper-element__nav-icon-fill {
  fill: var(--swiper-secondary-color);
}

@media screen and (max-width: 900px) {
  .swiper-element__nav-button {
    display: none;
  }
}