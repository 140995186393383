.admin__slide-image-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.admin__slide-image {
  height: 80%;
  position: relative;
  right: 16px;
}

.admin__half-circle-wrapper {
  width: 158.355px;
  position: absolute;
  bottom: 136.9px;
  right: -21.91px;
}

.admin__ring-wrapper {
  width: 335px;
  position: absolute;
  top: 0;
  left: 0;
}

.admin__flicker-wrapper {
  width: 35px;
  position: absolute;
  bottom: 113px;
  right: 119px;
}

.admin__rotate-wrapper {
  width: 91px;
  position: absolute;
  top: 44px;
  right: 322px;
}

@media screen and (max-width: 1220px) {
  .admin__slide-image {
    height: 72%;
    right: 14px;
  }

  .admin__half-circle-wrapper {
    width: 142px;
    bottom: 123px;
    right: -20px;
  }

  .admin__ring-wrapper {
    width: 302px;
  }

  .admin__flicker-wrapper {
    width: 32px;
    bottom: 102px;
    right: 107px;
  }

  .admin__rotate-wrapper {
    width: 82px;
    top: 40px;
    right: 290px;
  }
}

@media screen and (max-width: 900px) {
  .admin__slide-image {
    height: 50%;
    top: 10px;
  }

  .admin__half-circle-wrapper {
    width: 40.69%;
    bottom: 280.9px;
    right: -37.93%;
  }

  .admin__ring-wrapper {
    width: 85.97%;
    top: -122px;
    left: -33.59%;
  }

  .admin__flicker-wrapper {
    width: 32px;
    bottom: 102px;
    right: 107px;
  }

  .admin__rotate-wrapper {
    width: 9.74%;
    top: 10px;
    right: 24.36%;
  }
}

@media screen and (max-width: 700px) {
  .admin__slide-image-wrapper {
    justify-content: center;
    align-items: flex-end;
  }

  .admin__slide-image {
    height: 95%;
    top: 20px;
    right: 136px;
  }

  .admin__half-circle-wrapper {
    bottom: 210.9px;
    right: -17.93%;
  }

  .admin__flicker-wrapper {
    display: none;
  }

  .admin__rotate-wrapper {
    right: 34.36%;
  }
}
