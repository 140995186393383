.payment__slide-image {
  height: 68%;
  position: relative;
  top: 40px;
  right: 13px;
}

.payment__rhomb-wrapper {
  width: 424px;
  position: absolute;
  bottom: 0;
  right: -212px;
  opacity: 0;
}

.payment__half-circle-wrapper {
  width: 630px;
  position: absolute;
  top: 96px;
  left: -7%;
  z-index: 0;
}

.payment__circle-flicker-wrapper {
  width: 20px;
  position: absolute;
  bottom: 119px;
  right: 346px;
}

.payment__ring-flicker-wrapper {
  width: 160px;
  position: absolute;
  top: 10px;
  right: 112px;
}

@media screen and (max-width: 1220px) {
  .payment__slide-image {
    height: 60%;
    top: 20px;
  }

  .payment__rhomb-wrapper {
    width: 382px;
    right: -191px;
  }
  
  .payment__half-circle-wrapper {
    width: 284px;
    top: 86px;
    left: -22px;
  }
  
  .payment__circle-flicker-wrapper {
    width: 18px;
    bottom: 107px;
    right: 311px;
  }
  
  .payment__ring-flicker-wrapper {
    width: 144px;
    top: 9px;
    right: 101px;
  }
}

@media screen and (max-width: 900px) {
  .payment__slide-image {
    height: 50%;
  }

  .payment__rhomb-wrapper {
    width: 44.87%;
    top: -88px;
    right: 21.28%;
  }
  
  .payment__half-circle-wrapper {
    width: 37.18%;
    top: 121px;
    left: 0;
  }
  
  .payment__circle-flicker-wrapper {
    width: 3.59%;
    bottom: 220px;
    right: -13.08%;
  }
  
  .payment__ring-flicker-wrapper {
    width: 41.03%;
    top: 136px;
    right: -10.15%;
  }
}

@media screen and (max-width: 700px) {
  .payment__slide-image {
    height: 79%;
    top: -32px;
    right: 0;
  }
  
  .payment__circle-flicker-wrapper {
    bottom: 320px;
    right: 13.08%;
  }
  
  .payment__ring-flicker-wrapper {
    right: -6.15%;
  }
}