.notifications__slide-image {
  height: 80%;
  position: relative;
  top: 18px;
  right: 24px;
}

.notifications__ring-wrapper {
  width: 532px;
  position: absolute;
  left: -150px;
}

.notifications__rhomb-wrapper {
  width: 340px;
  position: absolute;
  top: -160px;
  right: 134px;
}

.notifications__big-flicker-wrapper {
  width: 26px;
  position: absolute;
  top: 52px;
  left: 547px;
}

.notifications__small-flicker-wrapper {
  width: 20px;
  position: absolute;
  left: 106px;
}

.notifications__rotate-wrapper {
  width: 84px;
  position: absolute;
  bottom: 129.9px;
  right: 205.9px;
}

@media screen and (max-width: 1220px) {
  .notifications__slide-image {
    height: 75%;
    right: 44px;
  }

  .notifications__ring-wrapper {
    width: 479px;
    left: -135px;
  }

  .notifications__rhomb-wrapper {
    width: 306px;
    top: -153px;
    right: 121px;
  }

  .notifications__big-flicker-wrapper {
    width: 23px;
    top: 47px;
    left: 492px;
  }

  .notifications__small-flicker-wrapper {
    width: 18px;
    left: 95px;
  }

  .notifications__rotate-wrapper {
    width: 76px;
    bottom: 117px;
    right: 75px;
  }
}

@media screen and (max-width: 900px) {
  .notifications__slide-image {
    height: 65%;
    right: 12px;
  }

  .notifications__ring-wrapper {
    width: 264px;
    top: 116px;
    left: -99px;
  }

  .notifications__rhomb-wrapper {
    width: 47.44%;
    top: 82px;
    right: -23.85%;
  }

  .notifications__big-flicker-wrapper {
    width: 23px;
    top: 47px;
    left: 292px;
  }

  .notifications__small-flicker-wrapper {
    width: 20px;
    top: 238px;
    left: 23px;
  }

  .notifications__rotate-wrapper {
    width: 14.43%;
    bottom: 48px;
    right: -9.19%;
  }
}

@media screen and (max-width: 700px) {
  .notifications__slide-image {
    height: 95%;
    right: -16px;
  }

  .notifications__big-flicker-wrapper {
    display: none;
  }

  .notifications__rotate-wrapper {
    bottom: 284px;
    right: 7.19%;
  }
}