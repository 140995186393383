.logo {
  width: fit-content;
  display: flex;
  position: absolute;
  top: 100px;
  left: 100px;
  z-index: 6;
}

.logo__wrapper {
  width: 264px;
  height: 88px;
}

.logo__main {
  width: 100%;
  height: 100%;
}

.logo__fill {
  fill: var(--neutral-secondary);
}

@media screen and (max-width: 1220px) {
  .logo {
    top: 80px;
    left: 80px;
  }

  .logo__wrapper {
    width: 211px;
    height: 70px;
  }
}

@media screen and (max-width: 900px) {
  .logo {
    top: 40px;
    left: unset;
  }

  .logo__wrapper {
    width: 144px;
    height: 48px;
  }
}
