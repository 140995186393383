.design__slide-image {
  height: 98%;
  position: relative;
}

.design__rhomb-wrapper {
  width: 274px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.design__half-circle-wrapper {
  width: 374.694px;
  position: absolute;
  top: 22.73px;
  left: 210.08px;
}

.design__flicker-wrapper {
  width: 26px;
  position: absolute;
  top: 142px;
  right: 511px;
}

.design__rotate-wrapper {
  width: 36px;
  position: absolute;
  top: 254.18px;
  right: 179.82px;
}

@media screen and (max-width: 1220px) {
  .design__slide-image {
    height: 85%;
  }
  
  .design__rhomb-wrapper {
    width: 247px;
  }
  
  .design__half-circle-wrapper {
    width: 337px;
    top: 20px;
    left: 189px;
  }
  
  .design__flicker-wrapper {
    width: 23px;
    top: 128px;
    right: 460px;
  }
  
  .design__rotate-wrapper {
    width: 32px;
    top: 229px;
    right: 162px;
  }
}

@media screen and (max-width: 900px) {
  .design__slide-image {
    height: 72%;
  }
  
  .design__rhomb-wrapper {
    width: 37.69%;
    top: 57px;
    left: -26.23%;
  }
  
  .design__half-circle-wrapper {
    width: 53.69%;
    top: 128.97px;
    left: unset;
    right: 13.24%;
  }
  
  .design__flicker-wrapper {
    width: 4.36%;
    top: 466px;
    right: -16.92%;
  }
  
  .design__rotate-wrapper {
    width: 6.4%;
    top: 2px;
    left: 38.46%;
  }
}

@media screen and (max-width: 700px) {
  .design__slide-image {
    height: 90%;
    bottom: 8px;
  }
  
  .design__rhomb-wrapper {
    top: 77px;
    left: -9.23%;
  }
  
  .design__flicker-wrapper {
    top: 416px;
    right: 6.92%;
  }
}