.slide {
  width: 100%;
  height: 100%;
  max-height: var(--max-height);
}

.slide__container {
  width: 90%;
  max-width: 1166px;
  height: 100%;
  display: grid;
  grid-template-columns: 54% fit-content(480px);
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 1260px) {
  .slide__container {
    justify-content: start;
    gap: 32px;
  }
}

@media screen and (max-width: 900px) {
  .slide {
    max-height: var(--max-height-mobile);
  }

  .slide__container {
    width: 100%;
    grid-template-columns: 48% fit-content(360px);
    gap: 0;
  }
}

@media screen and (max-width: 700px) {
  .slide__container {
    grid-template-columns: 1fr;
    grid-template-rows: 55% 1fr;
  }
}
