.text-element {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.text-element__title-box {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.text-element__icon {
  width: 100px;
}

.text-element__title {
  font-size: 44px;
  font-weight: 400;
  line-height: 1.09;
}

.text-element__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 60px;
}

.text-element__item {
  width: 100%;
  border-bottom: 1px solid var(--semantic-secondary);
  box-sizing: border-box;
  padding-bottom: 40px;
}

.text-element__item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.text-element__text {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
}

.text-element__text_weight_bold {
  font-weight: 700;
}

@media screen and (max-width: 1220px) {
  .text-element__title-box {
    gap: 16px;
  }

  .text-element__icon {
    width: 80px;
  }

  .text-element__title {
    font-size: 35px;
  }

  .text-element__list {
    gap: 32px;
    margin-top: 48px;
  }

  .text-element__item {
    width: 100%;
    padding-bottom: 32px;
  }

  .text-element__text {
    max-width: 384px;
    font-size: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .text-element {
    padding-right: 40px;
  }
}

@media screen and (max-width: 900px) {
  .text-element__title-box {
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }

  .text-element__icon {
    width: 34px;
  }

  .text-element__title {
    font-size: 20px;
    line-height: 1.7;
  }

  .text-element__list {
    gap: 14px;
    margin-top: 24px;
  }

  .text-element__item {
    padding-bottom: 14px;
  }

  .text-element__text {
    font-size: 14px;
    line-height: 1.43;
  }
}

@media screen and (max-width: 700px) {
  .text-element {
    height: 100%;
    align-self: flex-start;
    padding: 0 20px;
  }

  .text-element__title-box {
    position: sticky;
    top: 12px;
    left: 20px;
  }

  .text-element__list {
    height: 100%;
    margin-top: 0;
  }

  .text-element__item_type_with-btn {
    border-bottom: none;
    padding-bottom: 0;
  }

  .text-element__item_type_with-btn:first-child {
    flex: auto;
  }

  .text-element__item_type_scroll-btn {
    box-sizing: border-box;
    padding-bottom: 80px !important;
  }

  .text-element__text {
    max-width: 100%;
  }
}
