.integrations__slide-image {
  height: 84%;
  position: relative;
  top: 16px;
  left: 24px;
}

.integrations__circle-wrapper {
  width: 196px;
  position: absolute;
  bottom: 191px;
  right: -98px;
}

.integrations__rhomb-wrapper {
  width: 464px;
  position: absolute;
  top: 160px;
  left: -137px;
}

.integrations__flicker-wrapper {
  width: 40px;
  position: absolute;
  bottom: 52px;
  right: 181px;
}

.integrations__rotate-wrapper {
  width: 65.335px;
  position: absolute;
  top: 170.96px;
  right: 218.03px;
}

@media screen and (max-width: 1220px) {
  .integrations__slide-image {
    height: 75%;
    top: 32px;
    left: 12px;
  }

  .integrations__circle-wrapper {
    width: 176px;
    bottom: 172px;
    right: -88px;
  }

  .integrations__rhomb-wrapper {
    width: 418px;
    top: 144px;
    left: -123px;
  }

  .integrations__flicker-wrapper {
    width: 36px;
    bottom: 47px;
    right: 163px;
  }

  .integrations__rotate-wrapper {
    width: 59px;
    top: 154px;
    right: 196px;
  }
}

@media screen and (max-width: 900px) {
  .integrations__slide-image {
    height: 65%;
    top: 20px;
  }

  .integrations__circle-wrapper {
    width: 54.62%;
    top: -71px;
    right: -11.79%;
  }

  .integrations__rhomb-wrapper {
    width: 73.08%;
    top: 124px;
    left: -27.44%;
  }

  .integrations__flicker-wrapper {
    width: 5.13%;
    top: 250px;
    right: -11.54%;
  }

  .integrations__rotate-wrapper {
    width: 13.09%;
    top: unset;
    bottom: 58.8px;
    right: -17.26%;
  }
}

@media screen and (max-width: 700px) {
  .integrations__slide-image {
    height: 94%;
    top: 12px;
  }

  .integrations__flicker-wrapper {
    top: 200px;
    right: 11.54%;
  }

  .integrations__rotate-wrapper {
    bottom: 288.8px;
    right: 7.26%;
  }
}
