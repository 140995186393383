.custom-input {
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  transition: opacity 0.2s ease-in;
}

.custom-input_disabled {
  opacity: 0.6;
}

.custom-input__label {
  max-width: calc(100% - 32px);
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  transform-origin: top left;
  transform: translate(16px, 24px) scale(1);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1),
    max-width 200ms cubic-bezier(0, 0, 0.2, 1);
  z-index: 0;
  pointer-events: none;

  font-size: 16px;
  font-weight: 400;
  line-height: 1.125;
}

.custom-input__label_focused {
  max-width: calc(125% - 32px);
  transform: translate(16px, 14px) scale(0.75);
  pointer-events: auto;
  user-select: none;
}

.custom-input__input {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.125;
  overflow: auto;
  border-radius: 12px;
  border: 1px solid var(--form-secondary);
  background: var(--form-secondary);
  box-sizing: border-box;
  padding: 33px 16px 11px;
  transition: border 0.2s ease-in;
}

.custom-input__input:focus {
  border: 1px solid var(--form-active);
}

@media screen and (max-width: 900px) {
  .custom-input__label {
    max-width: calc(100% - 24px);
    transform: translate(12px, 14px) scale(1);
  }

  .custom-input__label_focused {
    max-width: calc(125% - 24px);
    transform: translate(12px, 5px) scale(0.75);
  }

  .custom-input__input {
    border-radius: 8px;
    padding: 21px 12px 5px;
  }
}
