.footer {
  width: 100%;
  height: 100vh;
  height: 100dvh; /* new browsers */
  background: var(--footer-active);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.footer__container {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  padding: 120px 100px 30px;
}

.footer__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__logo-wrapper {
  width: 344px;
  height: 114.626px;
}

.footer__logo {
  width: 100%;
  height: 100%;
}

.footer__text-block {
  display: flex;
  align-items: center;
  gap: 54px;
  margin-top: 28px;
}

.footer__text-block_type_mobile {
  display: none;
}

.footer__contacts-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
}

.footer__contact-text {
  color: var(--neutral-secondary);
  font-size: 22px;
  font-weight: 400;
  line-height: 1.18;
}

.footer__social-list {
  display: flex;
  align-items: center;
  gap: 30px;
}

.footer__social-link {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer__social-icon {
  width: 100%;
  height: 100%;
}

.footer__text-logo-wrapper {
  width: 235.289px;
  height: 118.81px;
}

.footer__text-logo {
  width: 100%;
  height: 100%;
}

.footer__text-logo-fill {
  fill: var(--footer-tertiary);
}

.footer__flicker-wrapper {
  width: 32px;
}

.footer__flicker-wrapper_type_mobile {
  display: none;
}

.footer__copyright {
  color: var(--footer-quaternary);
  text-align: center;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.4;
  margin-top: 62px;
}

.footer__ring-wrapper {
  width: 634px;
  position: absolute;
  top: -234px;
  left: 72px;
}

.footer__rhomb-wrapper {
  width: 294px;
  position: absolute;
  bottom: -247px;
  right: 216px;
}

.footer__rhomb-mobile-wrapper {
  display: none;
}

.footer__animation-wrapper {
  width: 100%;
  max-width: var(--max-width);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  margin-top: -100px;
}

@media screen and (max-width: 1220px) {
  .footer__container {
    padding: 80px 80px 24px;
  }

  .footer__logo-wrapper {
    width: 275px;
    height: 92px;
  }

  .footer__text-block {
    gap: 42px;
    margin-top: 22px;
  }

  .footer__contacts-block {
    gap: 28px;
  }

  .footer__contact-text {
    font-size: 18px;
  }

  .footer__text-logo-wrapper {
    width: 188px;
    height: 95px;
  }

  .footer__flicker-wrapper {
    width: 26px;
  }

  .footer__copyright {
    margin-top: 50px;
  }

  .footer__ring-wrapper {
    width: 507px;
    top: -187px;
    left: 58px;
  }

  .footer__rhomb-wrapper {
    width: 235px;
    bottom: -198px;
    right: 173px;
  }

  .footer__animation-wrapper {
    margin-top: -80px;
  }
}

@media screen and (max-width: 900px) {
  .footer__container {
    padding: 40px;
  }

  .footer__logo-wrapper {
    width: 184px;
    height: 62px;
  }

  .footer__text-block {
    display: none;
  }

  .footer__text-block_type_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin-top: 16px;
  }

  .footer__contacts-block {
    gap: 20px;
  }

  .footer__contact-text {
    font-size: 16px;
    line-height: 1.25;
  }

  .footer__text-logo-wrapper {
    width: 128px;
    height: 65px;
  }

  .footer__flicker-wrapper_type_mobile {
    display: block;
    width: 13.33%;
    position: absolute;
    bottom: 154px;
    left: -6.67%;
  }

  .footer__copyright {
    margin-top: 20px;
  }

  .footer__ring-wrapper {
    display: none;
  }

  .footer__rhomb-wrapper {
    width: 56.41%;
    bottom: -86px;
    right: -28.21%;
  }

  .footer__rhomb-mobile-wrapper {
    display: block;
    width: 82.84%;
    max-width: 340px;
    position: absolute;
    top: -122.77px;
    left: -8.4%;
    transform: rotate(165deg);
  }
}
