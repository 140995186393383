.features__slide-image {
  height: 101%;
  position: relative;
}

.features__big-half-circle-wrapper {
  width: 488px;
  position: absolute;
  bottom: 100px;
  right: -15%;
}

.features__small-half-circle-wrapper {
  width: 72px;
  position: absolute;
  bottom: 273px;
  right: 0;
}

.features__ring-wrapper {
  width: 532px;
  position: absolute;
  top: -183px;
  left: -34px;
}

.features__flicker-wrapper {
  width: 26px;
  position: absolute;
  bottom: 130px;
  right: 520px;
}

.features__rotate-wrapper {
  width: 149px;
  position: absolute;
  top: 15px;
  right: 248px;
}

@media screen and (max-width: 1220px) {
  .features__slide-image {
    height: 86%;
  }

  .features__big-half-circle-wrapper {
    width: 220px;
    bottom: 18px;
  }

  .features__small-half-circle-wrapper {
    width: 65px;
    bottom: 174px;
  }

  .features__ring-wrapper {
    width: 479px;
    top: -165px;
    left: -31px;
  }

  .features__flicker-wrapper {
    width: 23px;
    bottom: 117px;
    right: 468px;
  }

  .features__rotate-wrapper {
    width: 134px;
    top: 14px;
    right: 223px;
  }
}

@media screen and (max-width: 900px) {
  .features__slide-image {
    max-height: 520px;
  }

  .features__big-half-circle-wrapper {
    width: 102px;
    top: 165px;
    right: -102px;
  }

  .features__small-half-circle-wrapper {
    width: 30.6px;
    top: 236.4px;
    right: -102px;
  }

  .features__ring-wrapper {
    width: 98.46%;
    top: -118px;
    left: -36.92%;
  }

  .features__flicker-wrapper {
    width: 3.33%;
    bottom: 324px;
    right: -11.69%;
  }

  .features__rotate-wrapper {
    width: 27.69%;
    top: 321px;
    left: -38.36%;
  }
}

@media screen and (max-width: 700px) {
  .features__slide-image {
    height: 88%;
    bottom: 6px;
  }

  .features__big-half-circle-wrapper {
    right: 0;
  }

  .features__small-half-circle-wrapper {
    right: 0;
  }

  .features__flicker-wrapper {
    right: 5.15%;
  }

  .features__rotate-wrapper {
    left: 4.36%;
  }
}
