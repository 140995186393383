.store-text-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 20px;
}

.store-text-element__title {
  color: var(--neutral-secondary);
  font-size: 54px;
  font-weight: 600;
  line-height: 1.07;
}

.store-text-element__text {
  color: var(--neutral-secondary);
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
  padding-top: 12px;
}

.store-text-element__price {
  color: var(--neutral-secondary);
  font-size: 44px;
  font-weight: 500;
  line-height: 1.09;
  padding-top: 26px;
}

.store-text-element__currency {
  font-size: 34px;
  font-weight: 400;
  line-height: 1.12;
  opacity: 0.7;
}

.store-text-element__button-wrapper {
  margin-top: 42px;
}

@media screen and (max-width: 1220px) {
  .store-text-element {
    margin-top: 16px;
    margin-left: 32px;
  }

  .store-text-element__title {
    font-size: 43px;
  }

  .store-text-element__text {
    font-size: 16px;
    padding-top: 10px;
  }

  .store-text-element__price {
    font-size: 35px;
    padding-top: 20px;
  }

  .store-text-element__currency {
    font-size: 27px;
  }

  .store-text-element__button-wrapper {
    margin-top: 40px;
  }
}

@media screen and (max-width: 900px) {
  .store-text-element {
    margin: 0;
  }

  .store-text-element__title {
    font-size: 34px;
    line-height: 1.12;
  }

  .store-text-element__text {
    font-size: 14px;
    line-height: 1.28;
    padding-top: 8px;
  }

  .store-text-element__price {
    font-size: 34px;
    line-height: 1.12;
  }

  .store-text-element__currency {
    font-size: 24px;
    line-height: 1.16;
  }
}

@media screen and (max-width: 700px) {
  .store-text-element {
    position: relative;
    top: -62px;
    margin: 0 auto;
  }
}
