.half-circle {
  width: 100%;
  aspect-ratio: 1 / 2;
  position: relative;
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
  background: var(--circle-color);
  box-shadow: 0 64px 114px var(--shadow-color);
  transform: rotate(var(--rotate-angle));
}
