.swipeable-card__box-action {
    position: absolute;
    right: 0px;
    width: 82px;
    left: 100%;
    height: 100%;
    max-height: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.swipeable-card__action {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 77px;
    border-radius: 16px;
    background: #FFE5E9;
    color: #FF6376;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 128.571% */
}

.swipeable-card {
    overflow: hidden;
}

.swipeable-card__item {
    transition: all 0.1s linear;
    display: flex;
    height: fit-content !important;
    box-sizing: border-box !important;
    height: 84px !important;
    width: 100%;
}

.swipeable-card__item-inner {
    background: #fff;
    padding: 14px 12px;
    position: relative;
    z-index: 2;
    transition: all 0.1s linear;

    /* padding: 24px 20px; */
    height: fit-content !important;
    box-sizing: border-box !important;
    height: 84px !important;

    width: 280px;
    border-radius: 16px !important;
    /* background: rgba(255, 255, 255, 0.05); */
    position: relative;
    z-index: 1;
}

.swipeable-card__task__dots {
    position: absolute;
    top: 0px;
    right: 12px;

    display: flex;
    align-items: start;
    justify-content: start;
    width: 40px;
    height: 84px;
}

.swipeable-card__task__dots-svg {
    width: 100%;
    margin-top: 36px;
}

.swipeable-card__task {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    align-content: center;
    height: 100%;

}

.swipeable-card__task__call {
    color: #434A70;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 128.571% */
}

.swipeable-card__task__call-type {
    color: #434A70;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    /* 130% */
    margin-top: 10px;

    pointer-events: none;
    user-select: none;
}

.swipeable-card__task__call-and-time {
    display: flex;
    /* justify-content: space-between; */
    gap: 8px;
    width: 140px;
    pointer-events: none;
    user-select: none;
}

.swipeable-card__task__time {
    color: #8A8AA3;
    display: flex;
    align-items: center;
    gap: 4px;
    pointer-events: none;
    user-select: none;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;

    position: absolute;
    left: 109px
}

.swipeable-card__task__time__timer-icon {
    margin-top: -3px;
}

.swipeable-card_empty {
    color: #777786;
    text-align: center;

    font-family: "Nunito Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 80% */

    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

@media (max-width: 850px) {
    .swipeable-card__item-inner {
        width: calc(100%);
    }

    .swipeable-card__item {
        width: 100%;
    }

    .swipeable-card__box-action {
        left: unset;
        right: -82px;
    }
}