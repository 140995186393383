.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 18px;
    box-sizing: border-box;
    background: #fff;

    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;

    left: auto;
    max-width: 300px;
}

.header__box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 18px;
    box-sizing: border-box;
    background: #fff;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    left: auto;

    max-width: 300px;
}

/* .header_hidden {
    display: none;
} */

.header_hidden {
    display: none;
}

.header_no-bg {
    display: none;

    background: transparent;
    position: relative !important;
}

.header_opacity {
    /* opacity: 0; */
}

.header-title {
    color: #B1B1C0;
    font-feature-settings: 'clig'off, 'liga'off;
    font-family: Mayak;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 111.111%;
    position: relative;
    z-index: 1;

    margin: 0 16px 0 0;
    max-width: 60px;
}

.header__burger-and-rocket-box {
    display: flex;
    align-items: center;
    gap: 5px;
}

.header__burger-box {
    display: flex;
    flex-direction: column;
    gap: 2px;
    min-width: 18px;
}

.burger-line {
    height: 1.6px;
    border-radius: 600px;
    background: #555B7D;
}

.burger-line_first {
    width: 14px;
}

.burger-line_second {
    width: 18px;
}

.burger-line_third {
    width: 14px;
}

.header__history-link {
    opacity: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--bg-color);
    transition: all 0.2s ease-in-out;
}

.header__cosmonavt {
    /* opacity: 0; */
}

.header__history-link_active {
    background: #555B7D;
}

.header__cosmonavt-stroke {
    stroke: #555B7D;
    transition: all 0.2s ease-in-out;
}

.header__history-link_active .header__cosmonavt-stroke {
    stroke: #fff;
}