.menu-description__slide-image {
  height: 101%;
  position: relative;
}

.menu-description__rhomb-wrapper {
  width: 340px;
  position: absolute;
  top: 270px;
  left: -70px;
}

.menu-description__circle-wrapper {
  width: 236px;
  position: absolute;
  top: -118px;
  right: 218px;
}

.menu-description__flicker-wrapper {
  width: 26px;
  position: absolute;
  top: 270px;
  right: 119px;
}

.menu-description__rotate-wrapper {
  width: 65.335px;
  position: absolute;
  bottom: 142.37px;
  right: 134.04px;
}

@media screen and (max-width: 1220px) {
  .menu-description__slide-image {
    height: 86%;
  }

  .menu-description__rhomb-wrapper {
    width: 306px;
    top: 243px;
    left: -33px;
  }

  .menu-description__circle-wrapper {
    width: 212px;
    top: -106px;
    right: 196px;
  }

  .menu-description__flicker-wrapper {
    width: 23px;
    top: 243px;
    right: 107px;
  }

  .menu-description__rotate-wrapper {
    width: 59px;
    bottom: 128px;
    right: 121px;
  }
}

@media screen and (max-width: 900px) {
  .menu-description__slide-image {
    max-height: 520px;
  }

  .menu-description__rhomb-wrapper {
    width: 48.72%;
    top: 171px;
    left: -54.36%;
  }

  .menu-description__circle-wrapper {
    width: 60.51%;
    top: -87px;
    right: -13.33%;
  }

  .menu-description__flicker-wrapper {
    width: 3.33%;
    top: unset;
    bottom: 302px;
    right: -11.69%;
  }

  .menu-description__rotate-wrapper {
    width: 16.75%;
    top: 254px;
    right: -25.39%;
  }
}

@media screen and (max-width: 700px) {
  .menu-description__slide-image {
    height: 88%;
    bottom: 6px;
  }

  .menu-description__rhomb-wrapper {
    left: -24.36%;
  }

  .menu-description__flicker-wrapper {
    right: 7.69%;
  }

  .menu-description__rotate-wrapper {
    right: 9.39%;
  }
}
