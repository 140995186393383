.cart-counter {
  max-width: 100vw;
  height: 64px;
  position: absolute;
  bottom: 12px;
  left: 12px;
  right: 12px;
  border-radius: 16px;
  padding: 20px 22px 20px 20px;
  box-sizing: border-box;
  z-index: 3;
  background: #fff;
  box-shadow: 0px 8px 54px 0px rgba(126, 130, 165, 0.16);

  background: #E8EEFF;
}

.cart-counter__button-to-cart {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cart-counter__position-number {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  gap: 12px;
  align-items: center;
}

.cart-counter__number {
  text-align: right;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.cart-counter__button-text {
  color: #555B7D;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
}