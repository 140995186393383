.watch__slide-image {
  height: 80%;
  position: relative;
  top: 80px;
  left: 60px;
}

.watch__half-circle-wrapper {
  width: 288.806px;
  position: absolute;
  top: -216.48px;
  left: 175.92px;
  z-index: 1;
}

.watch__rhomb-wrapper {
  width: 569.72px;
  position: absolute;
  top: 71.99px;
  left: -306px;
  z-index: 2;
}

.watch__ring-wrapper {
  width: 634px;
  position: absolute;
  bottom: -300px;
  left: 72px;
  z-index: 0;
}

.watch__hole-rhomb-wrapper {
  width: 294px;
  position: absolute;
  bottom: 112px;
  right: -146.6px;
}

.watch__flicker-wrapper {
  width: 44px;
  position: absolute;
  top: 50px;
  right: 347px;
}

.watch__rotate-wrapper {
  width: 68.831px;
  position: absolute;
  bottom: 135.95px;
  left: 622.4px;
}

@media screen and (max-width: 1220px) {
  .watch__slide-image {
    height: 72%;
    top: 50px;
    left: 40px;
  }

  .watch__half-circle-wrapper {
    width: 231px;
    top: -173px;
    left: 141px;
  }

  .watch__rhomb-wrapper {
    width: 456px;
    top: 58px;
    left: -245px;
  }

  .watch__ring-wrapper {
    width: 507px;
    bottom: -240px;
    left: 58px;
  }

  .watch__hole-rhomb-wrapper {
    width: 235px;
    bottom: 90px;
    right: -117px;
  }

  .watch__flicker-wrapper {
    width: 35px;
    top: 40px;
    right: 278px;
  }

  .watch__rotate-wrapper {
    width: 55px;
    bottom: 109px;
    left: 498px;
  }
}

@media screen and (max-width: 900px) {
  .watch__slide-image {
    height: 50%;
    top: 0;
    left: 0;
  }

  .watch__half-circle-wrapper {
    width: 40.86%;
    top: -158.02px;
    left: 10.71%;
  }

  .watch__rhomb-wrapper {
    width: 82.84%;
    max-width: 340px;
    top: unset;
    bottom: -120.32px;
    left: -8.38%;
    transform: rotate(165deg);
  }

  .watch__ring-wrapper {
    width: 89.69%;
    max-width: 435px;
    bottom: unset;
    left: -57.44%;
  }

  .watch__hole-rhomb-wrapper {
    width: 85.03%;
    max-width: 360px;
    top: 109px;
    right: -47.33%;
  }

  .watch__flicker-wrapper {
    width: 6.22%;
    top: 145px;
    left: 18.94%;
  }

  .watch__rotate-wrapper {
    width: 15.33%;
    max-width: 65px;
    bottom: 218.69px;
    left: unset;
    right: 4.91%;
  }
}

@media screen and (max-width: 700px) {
  .watch__slide-image {
    height: 75%;
    top: -16px;
  }
}