.arrow-slide-down {
  display: none;
}

@media screen and (max-width: 900px) {
  .arrow-slide-down {
    display: flex;
    width: fit-content;
    position: absolute;
    bottom: 12px;
    z-index: 6;
  }

  .arrow-slide-down__wrapper {
    width: 21px;
    height: 21px;
  }

  .arrow-slide-down__icon {
    width: 100%;
    height: 100%;
  }

  .arrow-slide-down__icon-fill {
    fill: var(--swiper-main-color);
  }
}
