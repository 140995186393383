.partner-card {
  width: 100%;
  max-width: 585px;
  display: grid;
  grid-template-columns: 220px 1fr;
  align-items: center;
  gap: 30px;
  margin: 0 auto;
}

.partner-card__img-box {
  width: 100%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
}

.partner-card__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.partner-card__content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.partner-card__title {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.14;
}

.partner-card__text {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
  padding-top: 12px;
}

.partner-card__buttons-list {
  display: flex;
  gap: 16px;
  margin-top: 24px;
}

.partner-card__button {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 600px;
  border: 1px solid var(--link-button-color);
  box-sizing: border-box;
  padding: 11px 20px;

  color: var(--link-button-color);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.11;
}

.partner-card__button_type_color {
  border: none;
  background-color: var(--link-button-color);
  color: var(--neutral-secondary);
  padding: 12px 21px;
}

@media screen and (max-width: 1220px) {
  .partner-card {
    max-width: 468px;
    grid-template-columns: 176px 1fr;
    gap: 24px;
  }

  .partner-card__title {
    font-size: 22px;
  }

  .partner-card__text {
    font-size: 16px;
    padding-top: 10px;
  }

  .partner-card__buttons-list {
    gap: 12px;
    margin-top: 20px;
  }

  .partner-card__button {
    padding: 9px 16px;
    font-size: 14px;
    line-height: 1.14;
  }

  .partner-card__button_type_color {
    padding: 10px 17px;
  }
}

@media screen and (max-width: 900px) {
  .partner-card {
    max-width: 400px;
    grid-template-columns: 140px 1fr;
    gap: 16px;
  }

  .partner-card__title {
    font-size: 20px;
    line-height: 1.2;
  }

  .partner-card__text {
    font-size: 14px;
    line-height: 1.28;
    padding-top: 4px;
  }

  .partner-card__buttons-list {
    margin-top: 14px;
  }

  .partner-card__button {
    padding: 7px 15px;
  }

  .partner-card__button_type_color {
    padding: 8px 16px;
  }
}

@media screen and (max-width: 600px) {
  .partner-card {
    max-width: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .partner-card__img-box {
    max-width: 140px;
  }

  .partner-card__content {
    align-items: center;
    text-align: center;
  }
}
