.tariff-card-content {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
}

.tariff-card-content__subtitle {
  width: 100%;
  font-size: 30px;
  font-weight: 400;
  line-height: 1.13;
  border-bottom: 1px solid var(--semantic-secondary);
  padding-bottom: 36px;
  margin-top: 40px;
}

.tariff-card-content__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 40px;
}

.tariff-card-content__list_type_with-subtitle {
  margin-top: 34px;
}

.tariff-card-content__item {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.tariff-card-content__check-mark {
  width: 14px;
  height: 24px;
}

.tariff-card-content__check-mark-fill {
  fill: var(--semantic-tertiary);
}

.tariff-card-content__item-text {
  max-width: 373px;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.09;
}

@media screen and (max-width: 1220px) {
  .tariff-card-content__subtitle {
    font-size: 24px;
    padding-bottom: 28px;
    margin-top: 32px;
  }

  .tariff-card-content__list {
    gap: 14px;
    margin-top: 32px;
  }

  .tariff-card-content__list_type_with-subtitle {
    margin-top: 28px;
  }

  .tariff-card-content__item-text {
    max-width: 312px;
    font-size: 18px;
  }
}

@media screen and (max-width: 900px) {
  .tariff-card-content__subtitle {
    font-size: 14px;
    line-height: 1.28;
    padding-bottom: 14px;
    margin-top: 12px;
  }

  .tariff-card-content__list {
    margin-top: 30px;
  }

  .tariff-card-content__list_type_with-subtitle {
    margin-top: 20px;
  }

  .tariff-card-content__check-mark {
    height: 20px;
  }

  .tariff-card-content__item-text {
    max-width: 238px;
    font-size: 14px;
    line-height: 1.43;
  }
}
