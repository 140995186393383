.table-app {
  width: 840px;
  min-height: 540px;
  height: 100vh;
  height: 100dvh; /* new browsers */
  max-height: 540px;
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 12px;
  max-width: 840px;
  /* position: fixed; */
  /* left: 0; */
  /* top: 0; */
  padding: 10px;
  box-sizing: border-box;

  border-radius: 30px;
  background: #F2F2F3;

  display: grid;
  grid-template-columns: 1fr 270px;
  position: relative;

  position: absolute;
  z-index: 1000;
  right: 5%;
  /* bottom: 20px; */
}

.demo-page__table-app {
  display: flex;
  flex-direction: column;
  gap: 46px;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

}

.demo-page__motion-transcription {
  display: flex;
  align-items: center;
  gap: 20px;
  color: #434A70;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 120% */
  position: relative;
  z-index: 100;
  top: 5%;
  position: absolute;
  background: #fff;
  width: fit-content;
  min-width: 15%;
  left: 38%;
}

.demo-page__motion-transcription-svg {
  border-radius: 70px;
  background: #FFB31F;
  padding: 13px;
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  aspect-ratio: 1/1;

}

.table-schema__notification {
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 380px;
  top: 12px;
  left: 30%;
  /* color: #000;
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; */

  z-index: 10;
  border-radius: 16px;
  background: #EFF1F4;
  padding: 12px;
  box-sizing: border-box;

  display: grid;
  grid-template-columns: 46px minmax(0, 1fr);
  gap: 12px;
  max-height: 70px;
  background-color: #fff;
  z-index: 1001;
  right: 10%;
  top: 20px;
}


.table-schema__notification-svg {
  border-radius: 10px;
  background: #FFF;
  padding: 7px;
}

.table-schema__notification__text {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
  justify-content: flex-start;

}

.table-schema__notification__text__text {
  color: #000;
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  /* 100% */
}

.table-schema__notification__text__bot {
  color: #000;
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  /* 114.286% */
}