.tag {
  width: fit-content;
  display: flex;
  position: absolute;
  top: 60px;
  left: 77.64%;
  z-index: 6;
}

.tag__wrapper {
  border-radius: 600px;
  background: var(--neutral-active);
  padding: 18px;
}

.tag__text {
  color: var(--neutral-secondary);
  font-size: 22px;
  font-weight: 600;
  line-height: 1.09;
}

@media screen and (max-width: 1220px) {
  .tag {
    top: 40px;
  }

  .tag__wrapper {
    padding: 16px;
  }
  
  .tag__text {
    font-size: 18px;
  }
}

@media screen and (max-width: 900px) {
  .tag {
    top: 14px;
    left: unset;
    right: 20px;
  }
  
  .tag__wrapper {
    padding: 8px 12px;
  }
  
  .tag__text {
    font-size: 12px;
    line-height: 1.17;
  }
}