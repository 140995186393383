.tarrif-type {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
}

.tarrif-type__title-box {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.tarrif-type__subtitle {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.09;
}

.tarrif-type__title {
  font-size: 38px;
  font-weight: 500;
  line-height: 1.21;
}

.tarrif-type__price-box {
  display: flex;
  align-items: flex-end;
}

.tarrif-type__price {
  font-size: 44px;
  font-weight: 600;
  line-height: 1.04;
}

.tarrif-type__currency {
  font-size: 34px;
  font-weight: 500;
  padding-left: 6px;
}

.tarrif-type__period-box {
  border-radius: 10px;
  border: 1.2px solid var(--semantic-active);
  box-sizing: border-box;
  padding: 6px 12px;
  margin-left: 16px;
}

.tarrif-type__period {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.09;
}

@media screen and (max-width: 1220px) {
  .tarrif-type__subtitle {
    font-size: 18px;
  }

  .tarrif-type__title {
    font-size: 30px;
  }

  .tarrif-type__price {
    font-size: 34px;
  }

  .tarrif-type__currency {
    font-size: 26px;
    padding-left: 5px;
  }

  .tarrif-type__period-box {
    border-radius: 8px;
    padding: 5px 10px;
    margin-left: 12px;
  }

  .tarrif-type__period {
    font-size: 18px;
  }
}

@media screen and (max-width: 900px) {
  .tarrif-type__subtitle {
    font-size: 16px;
    line-height: 1.12;
  }

  .tarrif-type__title {
    font-size: 28px;
    line-height: 1.14;
  }

  .tarrif-type__price-box {
    align-items: baseline;
  }

  .tarrif-type__price {
    font-size: 28px;
    line-height: 1.14;
  }

  .tarrif-type__currency {
    font-size: 18px;
    line-height: 1.11;
    padding-left: 4px;
  }

  .tarrif-type__period-box {
    align-self: center;
    border-radius: 5px;
    padding: 1px 5px;
    margin-left: 10px;
  }

  .tarrif-type__period {
    font-size: 12px;
    line-height: 1.33;
  }
}
