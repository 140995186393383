.element-slide-from-bottom {
  width: 100%;
  height: 100%;
  max-height: var(--max-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 0;

  animation-play-state: running; 
}

@media screen and (max-width: 900px) {
  .element-slide-from-bottom {
    max-width: var(--max-width-mobile);
    max-height: var(--max-height-mobile);
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-3px);
  }
  100% {
    transform: translateX(3px);
  }
}