.slide-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* overflow: hidden; */
  background-color: #fff;
  height: 100dvh;
}


.slide-wrapper__container {
  width: 100%;
  max-width: var(--max-width);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media (max-width:3000px) {
  .slide-wrapper__container {
    position: relative;
  }
}

@media (max-width:1700px) {
  .slide-wrapper__container {
    position: unset;
  }
}