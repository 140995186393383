.tariff {
  width: 100vw;
  height: 100vw;
  max-height: var(--max-height);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  position: relative;
  box-sizing: border-box;
  padding: 60px 100px;
  z-index: 6;
  /* background-color: #fff; */
}

.tariff__half-circle-wrapper {
  width: 240px;
  position: absolute;
  bottom: -286.56px;
  left: 500.56px;
  /* display: none; */
}

.tariff__rhomb-wrapper {
  width: 340px;
  position: absolute;
  right: -70px;
}

.tariff__big-ring-wrapper {
  width: 480px;
  position: absolute;
  top: -169px;
  left: 45px;
}

.tariff__small-ring-wrapper {
  width: 306px;
  position: absolute;
  top: 230px;
}

.tariff__circle-flicker-wrapper {
  width: 20px;
  position: absolute;
  top: 186px;
  left: 45px;
}

.tariff__rotate-wrapper {
  width: 84px;
  position: absolute;
  bottom: 72.9px;
  left: 47.1px;
}

.tariff__half-circle-flicker-wrapper {
  width: 36px;
  position: absolute;
  top: 90px;
  right: 0;
  transform: rotate(45deg);
}

@media screen and (max-width: 1350px) {
  .tariff {
    padding: 60px 32px;
  }
}

@media screen and (max-width: 1220px) {
  .tariff {
    gap: 10px;
  }

  .tariff__half-circle-wrapper {
    width: 216px;
    bottom: -258px;
    left: 451px;
  }

  .tariff__rhomb-wrapper {
    width: 306px;
    right: -63px;
  }

  .tariff__big-ring-wrapper {
    width: 432px;
    top: -152px;
    left: 110px;
  }

  .tariff__small-ring-wrapper {
    width: 275px;
    top: 207px;
  }

  .tariff__circle-flicker-wrapper {
    width: 18px;
    top: 167px;
    left: 41px;
  }

  .tariff__rotate-wrapper {
    width: 76px;
    bottom: 66px;
    left: 42px;
  }

  .tariff__half-circle-flicker-wrapper {
    width: 32px;
    top: 81px;
  }
}

@media screen and (max-width: 900px) {
  .tariff {
    max-height: var(--max-height-mobile);
    padding: 60px 12px;
  }

  .tariff__half-circle-wrapper {
    width: 23.21%;
    bottom: -107.79px;
    left: 39.75%;
  }

  .tariff__rhomb-wrapper {
    width: 53.33%;
    bottom: 44px;
    right: -26.67%;
  }

  .tariff__big-ring-wrapper {
    width: 123.08%;
    top: -160px;
    left: -53.08%;
  }

  .tariff__small-ring-wrapper {
    width: 54.87%;
    top: unset;
    bottom: 78px;
    left: -27.44%;
  }

  .tariff__circle-flicker-wrapper {
    width: 9.74%;
    top: 61px;
    left: 12.82%;
  }

  .tariff__rotate-wrapper {
    width: 16.72%;
    top: 13.96px;
    left: unset;
    right: 16.73%;
  }

  .tariff__half-circle-flicker-wrapper {
    width: 6.15%;
    top: unset;
    bottom: 57px;
    right: 26.67%;
  }
}

@media screen and (max-width: 730px) {
  .tariff {
    display: flex;
    align-items: center;
    padding: 60px 0;
    height: 100dvh;
  }

  .tariff__swiper {
    height: 100%;
    box-sizing: border-box;
    padding: 0 12px;
  }
}